



































































































import Vue from "vue";
import {Backend} from "api-frontend";
import {authentication} from "mp-common/src/types/api/authenticationApi";
import {User} from "mp-common/src/types/entities/user";
import {settings as commonSettings} from "mp-common/settings";
import {getResourceUrl} from "@/services/getResource";
import router, {menuFolders, navigate, routes} from "@/router/routes";
import {RouteConfig} from "vue-router";
import "./components/subComponents/SharedMenuItem"
import {hasRoles} from "mp-common/src/helper/permissions";
import {settings} from "@/settings";

export default Vue.extend({
  name: 'Shared',
  data: function () {
    return {
      user: undefined as Omit<User, "password"> | undefined,
      backend: new Backend(settings.backendUrl, "", commonSettings.appVersion),
      expanded: localStorage.navbarExpanded === "true",
      authToken: localStorage.authToken,
      items: [] as RouteConfig[],
      folders: menuFolders,
    }
  },
  created: async function () {
    this.backend = new Backend(settings.backendUrl, this.authToken, commonSettings.appVersion);
    await this.getUser();
  },
  watch: {
    // Everytime the route changes, check user
    $route: async function () {
      const newAuthToken = localStorage.authToken;
      if (this.authToken !== newAuthToken) {
        this.authToken = newAuthToken;
        this.backend = new Backend(settings.backendUrl, newAuthToken, commonSettings.appVersion);
      }
      await this.getUser();
    },
    expanded: function () {
      localStorage.navbarExpanded = this.expanded;
    },
  },
  methods: {
    async getUser(): Promise<void> {
      this.user = await this.backend.query(authentication.getMe, undefined);
      if (this.user) {
        this.getMenuItems();
      }
    },
    getMenuItems() {
      this.items = (router.options.routes?.filter((route) => route.meta?.icon) ?? [])
          .filter((item) =>
              !item.meta?.roles ||
              hasRoles(this.user as User, ...item.meta.roles)
          )
    },
    getSubFolder(item: RouteConfig) {
      return this.folders.find((folder) => folder.subItems.includes(item))
    },
    getSubFolderItems(subFolder: any) {
      return this.items.filter((item) => subFolder.subItems.includes(item));
    },
    onMenuItemClick() {
      if (window.outerWidth < 600) {
        this.expanded = false;
      }
    },
    async logout() {
      const audio = new Audio(getResourceUrl("/Gehet hin in Frieden.mp3", this.backend));
      audio.volume = 0.5;
      audio.play();
      await navigate(routes.login);
      return;
    }
  },
})
