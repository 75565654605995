import * as t from "io-ts";
import {Roles, User} from "../entities/user";
import {omit, validateApi} from "api-common";

const Credentials = t.type({
    username: t.string,
    password: t.string
})

export const authentication = validateApi({
    login: {
        path: "/authentication/login",
        params: Credentials,
        queryParams: t.undefined,
        returns: t.type({
            user: t.type(omit(User.props, "password")),
            token: t.string
        }),
    },
    changePassword: {
        path: "/authentication/changePassword",
        params: t.type({
            currentPassword: t.string,
            newPassword: t.string
        }),
        queryParams: t.undefined,
        returns: t.type({
            user: t.type(omit(User.props, "password"))
        }),
        roles: [Roles.ACTIVE]
    },
    getMe: {
        path: "/authentication/getMe",
        params: t.undefined,
        queryParams: t.undefined,
        returns: t.type(omit(User.props, "password")),
        roles: [Roles.ACTIVE]
    }
})