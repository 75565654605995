
import Vue from "vue";
import {RouteConfig} from "vue-router";
import {navigate} from "@/router/routes";

export default Vue.component("SharedMenuItem", {
  name: 'SharedMenuItem',
  props: {
    item: {
      type: Object as () => RouteConfig,
      required: true
    },
    isSubItem: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    async onItemClick(item: RouteConfig) {
      this.$emit("select");
      await navigate(item);
    }
  },
  template: `
      <md-list-item :class="$route.path === item.path ? 'grey-background' : ''" v-on:click="onItemClick(item)">
        <md-icon v-if="!isSubItem">{{ item.meta.icon }}</md-icon>
        <span class="md-list-item-text"
              :style="isSubItem ? 'margin-left: 58px' : ''">{{ item.meta.menuName ? item.meta.menuName : item.name }}</span>
      </md-list-item>`
})
