import Vue from 'vue'
import '@/polyfills'
import Shared from '@/Shared.vue'
import router from '@/router/routes'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/black-green-dark.css'
import '@/assets/material-icons-and-roboto.css'
import '@/style/styles.scss'


Vue.config.productionTip = false
Vue.use(VueMaterial);

new Vue({
  el: '#app',
  router,
  template: '<Shared/>',
  components: { Shared },
})
