import Vue from 'vue'
import Router, {Route, RouteConfig} from 'vue-router'
import {authentication} from "mp-common/src//types/api/authenticationApi";
import {settings as commonSettings} from "mp-common/settings";
import {Backend} from "api-frontend";
import {Roles} from "mp-common/src/types/entities/user";
import {hasRoles} from "mp-common/src/helper/permissions";
import {settings} from "@/settings";

Vue.use(Router)

type CustomRouteConfig = {
    path: string,
    component: () => Promise<unknown>,
    name?: string,
    meta: {
        requiresAuth: boolean,
        icon?: string,
        menuName?: string,
    }
}

function validateRoutes<U extends CustomRouteConfig, T extends Record<string, U>>(input: T): T {
    return input;
}

export const routes = validateRoutes({
    login:
        {
            path: '/login',
            component: () => import("@/components/sites/public/Login.vue"),
            meta: {
                requiresAuth: false,
            },
        },
    privacy:
        {
            path: '/privacy',
            name: "Datenschutz",
            component: () => import("@/components/sites/public/Privacy.vue"),
            meta: {
                requiresAuth: false
            }
        },
    corona:
        {
            path: '/corona',
            name: "Aktuelle Corona Infos",
            component: () => import("@/components/sites/Corona.vue"),
            meta: {
                requiresAuth: true,
                icon: "people",
                menuName: "Corona"
            }
        },
    assignmentCoordinationOverview:
        {
            path: '/assignment-coordination/overview',
            name: "Wünsche",
            component: () => import("@/components/sites/AssignmentCoordinationOverview.vue"),
            meta: {
                requiresAuth: true,
                icon: "asff"
            }
        },
    planCreationPreferences:
        {
            path: '/plan-creation/preferences',
            name: "Einstellungen",
            component: () => import("@/components/sites/ModifyAssignmentPreferences.vue"),
            meta: {
                requiresAuth: true,
                icon: "asdf"
            }
        },
    currentPlan:
        {
            path: '/currentPlan',
            name: "Aktueller Plan",
            component: () => import("@/components/sites/CurrentPlan.vue"),
            meta: {
                requiresAuth: true,
                icon: "assignment"
            }
        },
    instructions:
        {
            path: '/instructions',
            name: "Anleitungen",
            component: () => import("@/components/sites/training/Instructions.vue"),
            meta: {
                requiresAuth: true,
                icon: "school"
            }
        },
    trainingRinging:
        {
            path: '/training/ringing',
            name: "Klingeln",
            component: () => import("@/components/sites/training/Bell.vue"),
            meta: {
                requiresAuth: true,
                icon: "school"
            }
        },
    profile:
        {
            path: '/',
            name: "Profil",
            component: () => import("@/components/sites/Profile.vue"),
            meta: {
                requiresAuth: true,
                icon: "person"
            }
        },
    mail:
        {
            path: '/mail',
            name: "E-Mails",
            component: () => import("@/components/sites/Mail.vue"),
            meta: {
                requiresAuth: true,
                icon: "asdf",
                roles: [Roles.EMAIL]
            }
        },
    familiesEdit:
        {
            path: '/families/edit',
            name: "Familien",
            component: () => import("@/components/sites/ModifyFamilies.vue"),
            meta: {
                requiresAuth: true,
                icon: "asdf",
                roles: [Roles.PLAN_GENERATOR]
            }
        },
    createPlan:
        {
            path: '/createPlan',
            name: "Plan generieren",
            component: () => import("@/components/sites/admin/CreatePlan.vue"),
            meta: {
                requiresAuth: true,
                roles: [Roles.PLAN_GENERATOR],
                icon: "asdf"
            }
        },
    selectCreatedPlan:
        {
            path: '/selectCreatedPlan',
            name: "Plan auswählen",
            component: () => import("@/components/sites/admin/SelectCreatedPlan.vue"),
            meta: {
                requiresAuth: true,
                roles: [Roles.PLAN_GENERATOR],
                icon: "asdf"
            }
        },
    statistic:
        {
            path: '/statistic',
            name: "Statistik",
            component: () => import("@/components/sites/admin/Statistic.vue"),
            meta: {
                requiresAuth: true,
                roles: [Roles.STATISTIC],
                icon: "asdf"
            }
        },
    roles: {
        path: '/roles',
        name: "Rollen",
        component: () => import("@/components/sites/Roles.vue"),
        meta: {
            requiresAuth: true,
            roles: [Roles.ADMIN],
            icon: "asdf"
        }
    },
// Routes not visible in menu
    assignmentCoordinationEdit:
        {
            path: '/assignment-coordination/edit',
            name: "Einteilungswünsche bearbeiten",
            component: () => import("@/components/sites/AssignmentCoordinationAnswer.vue"),
            meta: {
                requiresAuth: true,
            }
        },
    assignmentCoordinationResult:
        {
            path: '/assignment-coordination/result',
            name: "Einteilungswünsche anzeigen",
            component: () => import("@/components/sites/AssignmentCoordinationResult.vue"),
            meta: {
                requiresAuth: true,
                roles: [Roles.ASSIGNMENT_COORDINATOR]
            }
        },
    showCreatedPlan:
        {
            path: '/showCreatedPlan',
            name: "Erstellten Plan anzeigen",
            component: () => import("@/components/sites/admin/ShowCreatedPlan.vue"),
            meta: {
                requiresAuth: true,
                roles: [Roles.PLAN_GENERATOR],
            }
        },
    carolSinging:
        {
            path: '/carol-singing',
            name: "Sternsingen in der Cloud",
            component: () => import("@/components/sites/CarolSinging.vue"),
            meta: {
                requiresAuth: true,
            }
        },
});

export const menuFolders: {
    name: string,
    icon: string,
    isOpen: boolean,
    subItems: RouteConfig[]
}[] = [
    {
        name: "Einteilung",
        icon: "assignment_turned_in",
        isOpen: false,
        subItems: [routes.assignmentCoordinationOverview, routes.planCreationPreferences]
    },
    {
        name: "Training",
        icon: "school",
        isOpen: false,
        subItems: [routes.instructions, routes.trainingRinging]
    },
    {
        name: "Admin",
        icon: "attach_money",
        isOpen: false,
        subItems: [routes.createPlan, routes.selectCreatedPlan, routes.statistic, routes.familiesEdit, routes.roles]
    },
    {
        name: "Leitung",
        icon: "flight",
        isOpen: false,
        subItems: [routes.mail]
    }
]

async function checkAuth(to: Route, from: Route, next: any): Promise<void> {
    const currentUser = await new Backend(settings.backendUrl, localStorage.authToken, commonSettings.appVersion).query(authentication.getMe, undefined);
    if (!to.meta?.requiresAuth) {
        await next();
        return;
    }
    if (!currentUser || !hasRoles(currentUser, Roles.ACTIVE)) {
        localStorage.redirect = to.fullPath;
        await next("/login");
        return;
    }
    if (to.meta?.roles && !hasRoles(currentUser, ...to.meta.roles)) {
        await next("/");
        return;
    }
    await next();
}

export async function navigate(to: RouteConfig, queryParams?: { [key: string]: string | number }) {
    let paramsString = "";
    if (queryParams) {
        paramsString = `?${
            Object.entries(queryParams)
                .map(([key, value]) => `${key}=${value}`)
                .join("&")
        }`
    }
    return router.push(to.path + paramsString);
}

const router = new Router({
    mode: 'history',
    routes: Object.values(routes)
})

router.beforeEach(checkAuth)

export default router
