import * as t from "io-ts";
import {TypeOf} from "io-ts";
import {Enum} from "api-common";
import {omit, tsEnum} from "api-common";

export class Roles extends Enum<string> {
    public static PLAN_GENERATOR = new Roles("PLAN_GENERATOR");
    public static STATISTIC = new Roles("STATISTIC");
    public static ASSIGNMENT_COORDINATOR = new Roles("ASSIGNMENT_COORDINATOR");
    public static ADMIN = new Roles("ADMIN");
    public static EMAIL = new Roles("EMAIL");
    public static ACTIVE = new Roles("ACTIVE");

    public static getValues(): Roles[] {
        return super.getValues() as Roles[];
    }

    public static fromString(str: string): Roles {
        return super.fromString(str) as Roles
    }
}

export const User = t.type({
    id: t.number,
    username: t.string,
    password: t.string,
    firstName: t.string,
    lastName: t.string,
    email: t.union([t.string, t.null]),
    roles: t.array(tsEnum(Roles.ACTIVE))
})

export type User = TypeOf<typeof User>

export const UserWithoutPassword = t.type(omit(User.props, "password"))

export type UserWithoutPassword = TypeOf<typeof UserWithoutPassword>